import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;
const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;
const Headers = () => {
  return {
    headers: {
      token: `Bearer ${BearerToken()}`,
    },
  };
};
export const getUserById = async (uId) => {
  try {
    let res = await axios.post(`${apiURL}/api/user/single-user`, { uId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePersonalInformationFetch = async (userData) => {
  try {
    let res = await axios.post(`${apiURL}/api/user/edit-user`, userData);
    if(res.data.error){
      return res.data.error;
    }
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getOrderByUser = async (uId) => {
  try {
    let res = await axios.post(`${apiURL}/api/order/order-by-user`, { uId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePassword = async (formData) => {
  try {
    let res = await axios.post(`${apiURL}/api/user/change-password`, formData);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getCategories = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/category/all-category`);
    return res.data.Categories;
  } catch (e) {
    console.log(e);
  }
}
export const getProductByCategory = async (category) => {
  try {
    let res = await axios.get(`${apiURL}/api/category/${category}`);
    return res.data;
  } catch (e) {
    console.log(e);
  }
}
// Functions for EditProduct Modal
export const editProduct = async (product) => {
  console.log(product);
  /* Most important part for updating multiple image  */
  let formData = new FormData();
  if (product.pEditImages) {
      formData.append("pEditImages", product.pEditImages);
  }
  /* Most important part for updating multiple image  */
  formData.append("pId", product.pId);
  formData.append("pName", product.pName);
  formData.append("pDescription", product.pDescription);
  formData.append("pStatus", product.pStatus);
  formData.append("pCategory", product.pCategory._id);
  formData.append("pQuantity", product.pQuantity);
  formData.append("pPrice", product.pPrice);
  formData.append("pOffer", product.pOffer);
  formData.append("pImages", product.pImages);

  try {
    let res = await axios.post(`${apiURL}/api/product/edit-product`, formData);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getAllProduct = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/product/all-product`, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const uploadImage = async (image) => {
  let imageIds, personId;
  const bodyFormData = new FormData();
  for (let i = 0; i < image.length; i++) {
    bodyFormData.append('images', image[i]);
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      'token': `Bearer ${BearerToken()}`,
    }
  }
  const url = `${apiURL}/api/photo/add-photo`;
  await axios.post(url, bodyFormData, config)
    .then(async (response) => {
      imageIds = await response.data['Files Object Id'].split(',').slice(0, 3);
      personId = await response.data["Person Id"];
    }).catch((e) => {
      console.log(e.message);
    })
  return { imageIds, personId };
}
export const getAllCategory = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/category/all-category`, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
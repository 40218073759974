import React, { Fragment, useContext, useState, useEffect } from "react";
import Layout from "./Layout";
import { DashboardUserContext } from "./Layout";
import { updatePersonalInformationAction } from "./Action";
import { useHistory, useLocation } from "react-router-dom";
import swal from 'sweetalert';
import { Prompt } from 'react-router'

const ProfileComponent = () => {
  const history = useHistory();

  const { data, dispatch } = useContext(DashboardUserContext);
  const userDetails = data.userDetails !== null ? data.userDetails : "";

  const [fData, setFdata] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    photo:"",
    gender:"",
    dob:"",
    success: false,
  });

  useEffect(() => {
    setFdata({
      ...fData,
      id: userDetails._id,
      name: userDetails.name,
      email: userDetails.email,
      phone: userDetails.phoneNumber,
      gender: userDetails.gender,
      dob:userDetails.dob,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const [unsavedChanges, setUnsavedChanges] = useState(false);


  // Phone number state
  const [invalidPhone, setInvalidPhone] = useState(false);
  // Gender config
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const InitializeGenderBox = () => {
    const gender = fData.gender;
    if (gender === 'Male') {
      setMale(true);
      setFemale(false);
    } else if (gender === 'Female') {
      setFemale(true);
      setMale(false);
    }
  }
  useEffect(() => {
    InitializeGenderBox();
  });
  const maleClick = () => {
    if (userDetails.gender == null) {
      setFdata({...fData, gender: 'Male'});
      InitializeGenderBox();
      setUnsavedChanges(true);
    }
  }
  const femaleClick = () => {
    if (userDetails.gender == null) {
      setFdata({...fData, gender: 'Female'})
      InitializeGenderBox();
      setUnsavedChanges(true);
    }
  }
const sweetalert = function() {
  // Phone number validation
  if (String(fData.phone).length !== 10 || fData.phone <= 5000000000) {
    setInvalidPhone(true);
  } 
  // DOB validation
  else if (fData.dob == null || Number(fData.dob.substring(0,4)) > 2006) {
    swal({text: 'Invalid date of birth'});
  }
  else if (userDetails.dob !== null && fData.dob !== userDetails.dob) {
    setFdata({...fData, dob: userDetails.dob})
    swal({text: 'You cannot change date of birth'});
  } 
  else {
    setInvalidPhone(false);
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
         handleSubmit()
      }
    });
  }
}


  const handleSubmit = () => {
    console.log(fData);
    updatePersonalInformationAction(dispatch, fData);
    
  };
  if (data.loading) {
    return (
      <div className="w-full md:w-9/12 flex items-center justify-center ">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }
  return (
    <Fragment>
    <Prompt
      when={unsavedChanges}
      message="Unsaved changes will be discarded!"
    />
      <div className="flex flex-col w-full mt-2 mb-32 md:my-0 md:w-9/12 md:px-8">
        <div className="shadow-lg border">
          <div className="py-1 px-4 text-lg font-semibold">
            Personal Information
          </div>
          <hr className="my-2"/>
          <div className="pb-4 px-4 md:px-8 lg:px-16 flex flex-col space-y-4">
            {fData.success ? (
              <div className="bg-green-200 px-4 py-2 rounded">
                {fData.success}
              </div>
            ) : (
              ""
            )}
            <div className="flex flex-col space-y-2">
              <label htmlFor="name">Name</label>
              <input
                onChange={(e) => { setFdata({ ...fData, name: e.target.value }); setUnsavedChanges(true); }}
                value={fData.name}
                type="text"
                id="name"
                className="border px-4 py-2 w-full focus:outline-none rounded-xl"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="email">Email</label>
              <input
                value={fData.email}
                //readOnly
                placeholder="College email id"
                type="email"
                id="email"
                className="cursor-not-allowed border px-4 py-2 w-full focus:outline-none focus:cursor-not-allowed rounded-xl"
              />
              <span className="text-xs text-gray-500">
                You can't change your email
              </span>
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="number">Phone Number</label>
              <input
                onChange={(e) => { setFdata({ ...fData, phone: e.target.value }); setUnsavedChanges(true); }}
                value={fData.phone}
                type="number"
                id="number"
                className={`px-4 py-2 w-full" ${invalidPhone ? "border-2 border-red-600 rounded-xl focus:outline-none" : "border rounded-xl focus:outline-none"}`}
              />
              { invalidPhone && (
              <span className="pl-2 text-xs text-red-600">
                Enter a valid Phone number
              </span> )
              }
            </div>
            {/* <div className="flex flex-col space-y-1">
              <label htmlFor="number">Gender</label>
              <input
                
                value={fData.gender}
                type="text"
                id="gender"
                className="border px-4 py-2 w-full focus:outline-none"
              />
            </div> */}
            <div className="flex flex-col space-y-2 p-0">
            <label for="gender">Gender: {fData.gender}</label>
            </div>
            <div className="w-full flex justify-center items-center">
              <div id="male" className={`w-1/2 flex justify-center items-center p-1 ${male ? "border" : ""}`}
              onClick={maleClick}
              // onClick={setFdata({...fData, gender: 'Male'})}
              >
                Male
              </div>
              <div id="female" className={`w-1/2 flex justify-center items-center p-1 ${female ? "border" : ""}`}
              onClick={femaleClick}
              // onClick={setFdata({...fData, gender: 'Female'})}
              >
                Female
              </div>
            </div>
            {/* <div className="flex flex-col space-y-2 p-0">
            <label for="gender">Update Gender:</label>
            <select id="gender" className="border mt-1 px-4 py-2" onChange={(e) => setFdata({ ...fData, gender: e.target.value })}>
              <option value="Male" >Male</option>
              <option value="Female">Female</option>
              <option value="Bisexual">Bisexual</option>
              <option value="Transgender">Transgender</option>
              <option value="Other">Other</option>
            </select>
            </div> */}
            {/* <div className="flex flex-col mt-4">
              <label htmlFor="image">Profile Image</label>
              <input
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    error: false,
                    success: false,
                    photo: [...e.target.files],
                  })
                }
                type="file"
                accept=".jpg, .jpeg, .png"
                className="px-4 py-2 border focus:outline-none"
                id="image"
                name="images"
              />
              <button className="m-4 bg-white hover:bg-gray-100 text-gray-800 
          font-semibold py-2 px-4 border border-gray-400 rounded shadow w-24" onClick={uploadProfile}>Update Image</button>
            </div> */}
            <div className="flex flex-col space-y-2">
              <label htmlFor="name">Date Of Birth</label>
              <input
                onChange={(e) => {setFdata({ ...fData, dob: e.target.value }); setUnsavedChanges(true)}}
                value={fData.dob}
                type="date"
                id="dob"
                className="border px-4 py-2 w-full focus:outline-none"
              />
            </div>
            <div
              onClick={(e) => history.push("/user/setting")}
              style={{ background: "#FFF" }}
              className="w-full text-center cursor-pointer px-4 py-2 text-black border-2 border-gray rounded-xl"
            >
              Change Password
            </div>
            <div
              onClick={sweetalert}
              style={{ background: "#303031" }}
              className="w-full text-center cursor-pointer px-4 py-2 text-gray-100 rounded-xl"
            >
              Update Information
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const UserProfile = (props) => {
  return (
    <Fragment>
      <Layout children={<ProfileComponent />} />
    </Fragment>
  );
};

export default UserProfile;

import React, {
  Fragment,
  createContext,
  useReducer,
  useEffect,
  useState,
  useContext,
} from "react";
import Layout from "../layout";
import { DashboardUserContext } from "../dashboardUser/Layout";
// import TopCarousel from "./TopCarousel.js";
import ProductCategory from "./ProductCategory";
import { homeState, homeReducer } from "./HomeContext";
// import SingleProduct from "./SingleProduct";
import FeaturedProducts from "./FeaturedProducts";
import AllProducts from "./AllProducts";
import Slider from "./Slider";
import { isAuthenticate } from "../auth/fetchApi";

export const HomeContext = createContext();

const HomeComponent = () => {
  const[isOpen, setIsOpen] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false)
    }, 3000);
  }, []);

    const [toggle, setToggle] = useState(localStorage.getItem("toggle")==="true");
    useEffect(() => {
      localStorage.setItem("toggle", toggle);
    }, [toggle]);

  return (
    <Fragment>
      {toggle && isAuthenticate() ? (
        <div
          class="alert alert-success alert-dismissible fade show z-50"
          role="alert"
        >
          <strong>Successfully logged in!</strong> Check out the latest stuff
          ⚡️🚀🚀🚀
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : (
        <></>
      )}
      {/* <Slider />  Its being shifted down*/}
      {/* Category, Search & Filter Section */}
      <section className="m-1 md:mx-8 md:my-6 header">
        <ProductCategory />
      </section>

      {/* FeaturedProducts section added */}
      <FeaturedProducts />

      <Slider />
      {/* Products by different categories */}
      <AllProducts />

      {/* Product Section */}
      <section className="m-4 md:mx-8 md:my-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {/* <SingleProduct /> */}
      </section>
    </Fragment>
  );
};

const Home = (props) => {
  const [data, dispatch] = useReducer(homeReducer, homeState);
  return (
    <Fragment>
      <HomeContext.Provider value={{ data, dispatch }}>
        <Layout children={<HomeComponent />} />
      </HomeContext.Provider>
    </Fragment>
  );
};

export default Home;

import React, { Fragment, useEffect, useContext, useState } from "react";
import OrderSuccessMessage from "../home/OrderSuccessMessage";
import { ProductDetailsContext } from "./";
import { sliderImages } from "../../admin/dashboardAdmin/Action";
import { prevSlide, nextSlide } from "./Mixins";

// changes to slider
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './style.css';

const apiURL = process.env.REACT_APP_API_URL;

const Sliderchod = (props) => {
  const { data, dispatch } = useContext(ProductDetailsContext);
  const [slide, setSlide] = useState(0);
  setTimeout(function(){nextSlide(data.sliderImages.length, slide, setSlide)},4000);

  useEffect(() => {
    sliderImages(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="relative mb-8 mt-2 bg-gray-100 mx-2">
        {data.sliderImages.length > 0 ? (
          <img
            className="w-full rounded-lg object-contain h-40"
            src={`${apiURL}/api/photo/get-photo/${data.sliderImages[slide].slideImage}`}
            alt="sliderImage"
            // onClick={(e) =>
            //     nextSlide(data.sliderImages.length, slide, setSlide)
            // }
          />
        ) : (
          ""
        )}

        {/* {data?.sliderImages?.length > 0 ? (
          <>
            <svg
              onClick={(e) =>
                prevSlide(data.sliderImages.length, slide, setSlide)
              }
              className={`z-10 absolute top-0 left-0 mt-12 flex justify-end items-center box-border flex justify-center w-12 h-12 text-gray-400 cursor-pointer`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <svg
              onClick={(e) =>
                nextSlide(data.sliderImages.length, slide, setSlide)
              }
              className={`z-10 absolute top-0 right-0 mt-12 flex justify-start items-center box-border flex justify-center w-12 h-12 text-gray-400 cursor-pointer`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
            <div className="absolute inset-0 flex items-center justify-center">
              <a
                href="#shop"
                // style={{ background: "#303031" }}
                className="cursor-pointer box-border text-2xl text-white px-4 py-2 rounded"
              >
                
              </a>
            </div>
          </>
        ) : null} */}
      </div>



      {/* changes to slider */}
      {/* <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src={carousel} alt="First slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src={carousel2} alt="Second slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src={carousel} alt="Third slide" />
          </div>
        </div>
      </div> */}

      <OrderSuccessMessage />
    </Fragment>
  );
};

export default Sliderchod;

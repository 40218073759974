import React, { Fragment, useState, useContext } from "react";
import { loginReq } from "./fetchApi";
import { LayoutContext } from "../index";
import {createUserWithEmailAndPassword,signInWithEmailAndPassword , updateProfile} from "firebase/auth"
import { auth, db } from "../../../firebase";
import {setDoc , doc} from "firebase/firestore"
import swal from "sweetalert";

const Login = (props) => {
  const { data: layoutData, dispatch: layoutDispatch } =
    useContext(LayoutContext);
  const [loading1, setLoading1] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
    error: false,
    loading: true,
  });

  const alert = (msg) => <div className="text-xs text-red-500">{msg}</div>;

  const formSubmit = async () => {
    setData({ ...data, loading: true });
    try {
      let responseData = await loginReq({
        email: data.email.toLowerCase(),
        password: data.password,
      });
      // let email = data.email.toLowerCase();
      // let displayName = data.email.toLowerCase();
      if (responseData.error) {
        setData({
          ...data,
          loading: false,
          error: responseData.error,
          password: "",
        });
        setLoading1(false)
      } else if (responseData.token) {
        try{
          await signInWithEmailAndPassword(auth,data.email.toLowerCase(),data.password);
          console.log("Signed In WIth Email Password");
          await swal({text: 'Successfully logged In'});
          }catch (error){
            console.log(error)
          }
        setData({ email: "", password: "", loading: false, error: false });
        localStorage.setItem("jwt", JSON.stringify(responseData));
        window.location.href = "/";
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="text-center text-2xl mb-6 ">Login</div>
      {layoutData.loginSignupError ? (
        <div className="bg-red-200 py-2 px-4 rounded">
          You need to login for checkout. Haven't account? Create new one.
        </div>
      ) : (
        ""
      )}
      <form className="space-y-4 rounded-3">
        <div className="flex flex-col">
          {/* Login Input*/}
          <input
            onChange={(e) => {
              setData({ ...data, email: e.target.value, error: false });
              layoutDispatch({ type: "loginSignupError", payload: false });
            }}
            placeholder="email@nie.ac.in"
            value={data.email}
            type="text"
            id="name"
            className={`${
              !data.error ? "" : "border-red-500"
            } px-4 py-2 focus:outline-none border rounded-3`}
          />
          {!data.error ? "" : alert(data.error)}
        </div>
        <div className="flex flex-col">
          <input
            onChange={(e) => {
              setData({ ...data, password: e.target.value, error: false });
              layoutDispatch({ type: "loginSignupError", payload: false });
            }}
            placeholder="********"
            value={data.password}
            type="password"
            id="password"
            className={`${
              !data.error ? "" : "border-red-500"
            } px-4 py-2 focus:outline-none border rounded-3
`}
          />
          {!data.error ? "" : alert(data.error)}
        </div>
        <div className="flex flex-col space-y-2 md:flex-row md:justify-between md:items-center">
          <div></div>
          <a className="block text-gray-600" href="https://api.sellular.club/forgot-password">
            Lost your password?
          </a>
        </div>

        {loading1 ? (
          <button
            class="btn btn-primary"
            style={{ background: "#303031" }}
            type="button"
            disabled
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>{" "}
            Loading...
          </button>
        ) : (
          <>
            {" "}
            <div
              onClick={(e) => formSubmit() && setLoading1(true)}
              style={{ background: "#303031" }}
              className="font-medium px-4 py-2 text-white text-center cursor-pointer rounded-3"
            >
              Login
            </div>
          </>
        )}
      </form>
    </Fragment>
  );
};

export default Login;

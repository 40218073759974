import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from "./components/context/AuthContext";
import { ChatContextProvider } from "./components/context/ChatContext";
import * as serviceWorker from "./serviceWorker";
import "./chatstyle.css"
ReactDOM.render(
  <AuthContextProvider>
    <ChatContextProvider>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </ChatContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();

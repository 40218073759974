import React from "react";
import Navber from "../partials/Navber";
import Footer from "../partials/Footer";
import Home from "./Home";
import { useParams } from "react-router-dom";
import { auth } from "../../../firebase";
import { useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
const Chat = (props) => {
    const [user, setUser] = useState();
    onAuthStateChanged(auth,(result) => {
      if(result){
        setTimeout(() => {
          setUser(true)
        },100)
      }
    })
    const { name } = useParams()
    if(user){
        return(
            <div>
                <Navber />
                <Home userName={name}/>
                <Footer />
            </div>
        );
    }
    else{
        return(
            <div className="flex items-center justify-center p-8">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
        )
    }
    
};

export default Chat
import React, { useContext } from "react";
import Cam from "../img/cam.png";
import Add from "../img/add.png";
import More from "../img/more.png";
import Messages from "./Messages";
import Input from "./Input";
import { ChatContext } from "../../../context/ChatContext";

function Chat(props) {
  const { data } = useContext(ChatContext);
  const OpenSidebar = function() {
    props.OpenSidebar(true);
  }

  return (
    <div className="chat">
      <div className="chatInfo bg-gray-400">
        <span className='text-2xl'
        onClick={OpenSidebar}>
          &#8592;
        </span>
        <span className="pl-2 text-lg">{data.user?.displayName}</span>
        {/* <div className="chatIcons">
          <img src={Cam} alt="" />
          <img src={Add} alt="" />
          <img src={More} alt="" />
        </div> */}
      </div>
      <Messages />
      <Input/>
    </div>
  );
};

export default Chat;

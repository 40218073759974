import React, { useState } from 'react'
import Sidebar from './components/Sidebar'
import Chat from './components/Chat'
const Home = (props) => {
  const [OpenSidebar, setOpenSidebar] = useState(true);
  return (
    <div className='home'>
      <div className="container" style={{background: 'white'}}>
        {OpenSidebar && (<Sidebar OpenSidebar={setOpenSidebar} userName={props.userName}/>)}
        <Chat OpenSidebar={setOpenSidebar}/>
      </div>
    </div>
  )
}

export default Home
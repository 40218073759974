import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import './newCollage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
//import Navber from "../partials/Navber";
//import Footer from "../partials/Footer";
import { Fragment } from 'react';
import swal from 'sweetalert';

export default function AddNewCollege() {

  //Form events
  // const [email,setEmail]=useState('');
  // const [name,setName]=useState('');
  // const [collageEmail,setCollageEmail]=useState('');
  // const [contactNumber,setContactNumber]=useState('');
  // const [collageName,setCollageName]=useState('');
  // const [yearOfPassing,setYearOfPassing]=useState('');


  //submit events
  // const handelSubmit=(e)=>{
  //   e.preventDefault();
  //   console.log(email,name,collageEmail,contactNumber,collageName,yearOfPassing);
  // }
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [collegeemail, setCollegeemail] = useState('');
  const [phone, setPhone] = useState('');
  const [college, setCollege] = useState('');
  const [yearofpassing, setYearofpassing] = useState('');
  const [whysellular, setWhysellular] = useState('');
  const [additional, setAdditional] = useState('');

  const submitHandler = e => {
    e.preventDefault();
    axios.post('https://sheet.best/api/sheets/2c3e53dc-b643-4506-8f32-429c180b963e', 
      { email, name, collegeemail, phone, college, yearofpassing, whysellular, additional }
    )
    swal("We are coming soon!");
    setEmail('');
    setName('');
    setCollegeemail('');
    setPhone('');
    setCollege('');
    setYearofpassing('');
    setWhysellular('');
    setAdditional('');
  }


  return (
    <>
    <div className='mainForm p-3 pt-4'>
      <form autoComplete='off' className='form-group space-y-4' >
        <div className='flex flex-col'>
        <input placeholder="Enter your Email" type="email" id="email" name="email" value={email} className='my-2 px-4 py-2 focus:outline-none border rounded-3' onChange = {(e) => setEmail(e.target.value)} />
        </div>
        <div className='flex flex-col'>
        <input placeholder="Enter your Full Name" type="text" id="name" name="name" value={name} className='my-2 px-4 py-2 focus:outline-none border rounded-3' onChange = {(e) => setName(e.target.value)}/> 
        </div>
        <div className='flex flex-col'>
        <input placeholder="Enter your Collage Email ID" type="email" id="collegeemail" name="collegeemail" value={collegeemail} className='my-2 px-4 py-2 focus:outline-none border rounded-3' onChange = {(e) => setCollegeemail(e.target.value)}/> 
        </div>
        <div className='flex flex-col'>
        <input placeholder="Enter your Contact Number" type="tel" id="phone" name="phone" value={phone} className='my-2 px-4 py-2 focus:outline-none border rounded-3' onChange = {(e) => setPhone(e.target.value)}/>
        </div>
        <div className='flex flex-col'>
        <input placeholder="Enter your Collage Name" type="text" id="college" name="college" value={college} className='my-2 px-4 py-2 focus:outline-none border rounded-3' onChange = {(e) => setCollege(e.target.value)}/>
        </div>
        <div className='flex flex-col'>
        <input placeholder="Enter Year of passing" type="text" id="yearofpassing" name="yearofpassing" value={yearofpassing} className='my-2 px-4 py-2 focus:outline-none border rounded-3' onChange = {(e) => setYearofpassing(e.target.value)}/>
        </div>
        <div className='flex flex-col'>
        <label>How Do You Think Sellular Can Help in your College : </label>
        <textarea class="form-control ml-2" id="whysellular" name="whysellular" value={whysellular} rows="3" onChange = {(e) => setWhysellular(e.target.value)}></textarea>
        </div>
        <div className='flex flex-col'>
        <label>Additional Information : </label>
        <textarea className="form-control ml-2" id="additional" name="additional" value={additional} rows="3" onChange = {(e) => setAdditional(e.target.value)}></textarea>
        </div>
        <div className='d-flex justify-content-center pt-3'>
          <button onClick={submitHandler} className='btn btn-primary w-1/2'>Submit</button>
        </div>
      </form>
    </div>
    </>
  )
  
}
import React, { useEffect,useState } from 'react';
import './style.css';
import { getAllRecommended } from '../../admin/Recommended/FetchApi';
import { useHistory } from "react-router-dom";
// import Axios from 'axios';
const apiURL = process.env.REACT_APP_API_URL;
function FeaturedProducts() {
  const history = useHistory();
  // let recommended = [];
  const [product,setProduct] = useState([])
  // let isLoading = true;
  useEffect(() => {
    fetchData();
  },[])
  const fetchData = async () => {
    const a = await getAllRecommended();
    setProduct(a);
  };

    return (
        <div>
            <div className=''>
                <div className='recommended-for-you w-full inline'>
                    Recommended for you
                </div>
            <div/>
            
                <div className='featured-products'>
                {product && product.length > 0 ? (
                  product.map( (item, key) => {
                    return (
                      <div className="products-server drop-shadow-2xl">
                        <div className="relative m-1 bg-origin-content rounded-lg">
                          <div className="h-56 w-40 md:w-56 w-40 md:w-56 flex justify-center">
                            <img
                              onClick={() => {
                                history.push(`/products/${item._id}`);
                                window.location.reload();
                              }}
                              className="border-2 h-32 w-32 object-scale-down object-center cursor-pointer p-2 rounded-lg border bg-gray-200"
                              src={`${apiURL}/api/photo/get-photo/${item.pImages[0]}`}
                              alt=""
                            />
                          </div>
                          <div className="border-2 -mt-52 h-40 rounded-lg bg-origin-content bg-gray-100 px-2 flex justify-center">
                            <div className=" items-center  justify-center mt-2 bottom-0 mb-3  position-absolute pb-2">
                              <div className="text-gray-600 font-light truncate">
                                {item.pName}
                              </div>
                            </div>
                            <div className="text-center bottom-0 position-absolute pb-1">
                              ₹{item.pPrice}.00
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span>
                      No product found
                      </span>
                )}
                </div>
                </div>
            </div>

    );
}

export default FeaturedProducts

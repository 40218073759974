import React, { Fragment, createContext, useReducer, useEffect, useState } from "react";
import AdminLayout from "../layout";
import ProductMenu from "./ProductMenu";
import ProductTable from "./ProductTable";
import { productState, productReducer } from "./ProductContext";
import { getRecommended,deleteProduct } from "./FetchApi";
import { getSingleProduct } from "../../shop/productDetails/FetchApi";
/* This context manage all of the products component's data */
export const ProductContext = createContext();
const apiURL = process.env.REACT_APP_API_URL;



const ProductComponent = () => {
  let [recommended,setRecommended] = useState([])
  const [recommendedIds,setRecommendedIds] = useState()
  let isLoading = true;
  useEffect( () => {
    
    myfunc();
    isLoading=false;
  }, [])
  async function myfunc(){
   let products = await getRecommended();
   await setRecommended(products);
  }
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <ProductMenu />
      {recommended && recommended.length > 0 ? (
              recommended.map( (item, key) => {
                return (
                  <div className="w-full h-24 flex flex-row" key={key}>
          <div className="w-1/3">
            <img 
              className="p-2 object-scale-down object-center h-full w-full"
              src={`${apiURL}/api/photo/get-photo/${item.pImages[0]}`} alt="Product"
            />
          </div>
          <div className="w-2/3 text-left items-center p-2">
            <h5>{item.pName}</h5>
            <p className="text-sm">{item.pDescription}</p>
          </div>
          <span
            onClick={(e) => deleteProduct(item._id)}
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
          >
            <svg
              className="w-6 h-6 fill-current text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
                );
              })
            ) : (
              <span>
                  No product found
                  </span>
            )}
      {/* <ProductTable/> */}
    </div>
  );
};

const Recommended = (props) => {
  /* To use useReducer make sure that reducer is the first arg */
  const [data, dispatch] = useReducer(productReducer, productState);

  return (
    <Fragment>
      <ProductContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<ProductComponent />} />
      </ProductContext.Provider>
    </Fragment>
  );
};

export default Recommended;

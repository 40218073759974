import React, { useState } from "react";
import Navbar from "./Navbar"
import Search from "./Search"
import Chats from "./Chats"
const Sidebar = (props) => {

  const [OpenSidebar, setOpenSidebar] = useState(true);
  props.OpenSidebar(OpenSidebar);
  return (
    <div className="sidebar">
      <Navbar />
      <Search userName={props.userName}/>
      <Chats OpenSidebar={setOpenSidebar}/>
    </div>
  );
};

export default Sidebar;

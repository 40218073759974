import React, { Fragment, useState } from "react";
import { signupReq } from "./fetchApi";
import {
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { auth, db } from "../../../firebase";

const Signup = (props) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    cPassword: "",
    error: false,
    loading: false,
    success: false,
  });
  let email = data.email.toLowerCase();
  const [loading1, setLoading1] = useState(false);

  const alert = (msg, type) => (
    <div className={`text-sm text-${type}-500`}>{msg}</div>
  );

  const formSubmit = async () => {
    setData({ ...data, loading: true });
    if (data.cPassword !== data.password) { 
        setLoading1(false);
      return setData({
        ...data,
        error: {
          cPassword: "Password doesn't match",
          password: "Password doesn't match",
        },
      });

    }
    // try {
      // let responseData = await signupReq({
      //   name: data.name,
      //   email: data.email.toLowerCase(),
      //   password: data.password,
      //   cPassword: data.cPassword,
      // });
      let displayName;
      if(data.name){
        displayName=data.name;
      }
      signupReq({
        name: data.name,
        email: data.email.toLowerCase(),
        password: data.password,
        cPassword: data.cPassword,
      })
      try{
          const res = await createUserWithEmailAndPassword(auth,data.email.toLowerCase(),data.password)
              await setDoc(doc(db, "users", res.user.uid), {
                uid: res.user.uid,
                displayName:data.name,
                email,
              })
              await setDoc(doc(db, "userChats", res.user.uid), {});
              await updateProfile(auth.currentUser,{displayName:data.name})
        }catch(err){
          console.log(err)
        }
      // if (responseData.error) {
      //           setLoading1(false);
      //   setData({
      //     ...data,
      //     loading: false,
      //     error: responseData.error,
      //     password: "",
      //     cPassword: "",
      //   });
      // } else if (responseData.success) {
      //   const res = await createUserWithEmailAndPassword(
      //     auth,
      //     data.email.toLowerCase(),
      //     data.password
      //   );
      //   // const actionCodeSettings = {
      //   //   url: "https://sellular-firebase-db.web.app",
      //   //   handleCodeInApp: true,
      //   // };
      //   // let a = await sendEmailVerification(
      //   //   auth.currentUser,
      //   //   actionCodeSettings
      //   // );
      //   // console.log(a);
      //   console.log("Result for createUserWithEmailPass : ");
      //   console.log(res)
      //   try {
      //     //Update profile
      //     // await updateProfile(res.user, {
      //     //   displayName,
      //     // });
      //     //create user on firestore
      //     await setDoc(doc(db, "users", res.user.uid), {
      //       uid: res.user.uid,
      //       displayName,
      //       email,
      //     });
      //     //create empty user chats on firestore
      //     await setDoc(doc(db, "userChats", res.user.uid), {});
      //   } catch (err) {
      //     console.log(err);
      //   }
      //   setData({
      //     success: responseData.success,
      //     name: "",
      //     email: "",
      //     password: "",
      //     cPassword: "",
      //     loading: false,
      //     error: false,
      //   });
      // }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return (
    <Fragment>
      <div className="text-center text-2xl mb-6">Register</div>
      <form className="space-y-4">
        {data.success ? (
          <div className="text-success">
            You have successfully created your account check your mail to
            activate.
            <span role="img" aria-label="rockets">
              🚀🚀🚀
            </span>
          </div>
        ) : (
          <>
            <div className="flex flex-col">
              <input
                onChange={(e) =>
                  setData({
                    ...data,
                    success: false,
                    error: {},
                    name: e.target.value,
                  })
                }
                placeholder="apka shubh nam"
                value={data.name}
                type="text"
                id="name"
                className={`${
                  data.error.name ? "border-red-500" : ""
                } px-4 py-2 focus:outline-none border rounded-3`}
              />
              {!data.error ? "" : alert(data.error.name, "red")}
            </div>
            <div className="flex flex-col">
              <input
                onChange={(e) =>
                  setData({
                    ...data,
                    success: false,
                    error: {},
                    email: e.target.value,
                  })
                }
                placeholder="email@nie.ac.in"
                value={data.email}
                type="email"
                id="email"
                className={`${
                  data.error.email ? "border-red-500" : ""
                } px-4 py-2 focus:outline-none border rounded-3`}
              />
              {!data.error ? "" : alert(data.error.email, "red")}
            </div>
            <div className="flex flex-col">
              <input
                onChange={(e) =>
                  setData({
                    ...data,
                    success: false,
                    error: {},
                    password: e.target.value,
                  })
                }
                placeholder="password dalo"
                value={data.password}
                type="password"
                id="password"
                className={`${
                  data.error.password ? "border-red-500" : ""
                } px-4 py-2 focus:outline-none border rounded-3`}
              />
              {!data.error ? "" : alert(data.error.password, "red")}
            </div>
            <div className="flex flex-col">
              <input
                onChange={(e) =>
                  setData({
                    ...data,
                    success: false,
                    error: {},
                    cPassword: e.target.value,
                  })
                }
                value={data.cPassword}
                placeholder="pakka na?"
                type="password"
                id="cPassword"
                className={`${
                  data.error.cPassword ? "border-red-500" : ""
                } px-4 py-2 focus:outline-none border rounded-3`}
              />
              {!data.error ? "" : alert(data.error.cPassword, "red") } 
            </div>
            <div className="flex flex-col space-y-2 md:flex-row md:justify-between md:items-center rounded-3">
              <a className="block text-gray-600" href="https://api.sellular.club/forgot-password">
                Lost your password?
              </a>
            </div>

            {loading1 ? (
              <button
                class="btn btn-primary"
                style={{ background: "#303031" }}
                type="button"
                disabled
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Loading...
              </button>
            ) : (
              <>
                {" "}
                <div
                  onClick={(e) =>
                    formSubmit() &&
                    setData({
                      ...data,
                      success: true,
                      error: {},
                      cPassword: e.target.value,
                    }) &&
                    setLoading1(true)
                  }
                  style={{ background: "#303031" }}
                  className="px-4 py-2 text-white text-center cursor-pointer font-medium rounded-3"
                >
                  Create an account
                </div>
              </>
            )}
          </>
        )}
      </form>
    </Fragment>
  );
};

export default Signup;

import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { logout } from "./Action";
import { DashboardUserContext } from "./Layout";
import profile from "./images/profile.jpg";
import axios from "axios";
import { ref,uploadBytesResumable, getDownloadURL} from "firebase/storage";
import { auth,db,storage } from "../../../firebase";
import "./userImage.css";
import { updateProfile } from "firebase/auth";
import { doc, setDoc,getDoc } from "firebase/firestore";
import swal from "sweetalert";
import dp from "../../../static/pic1.png";
const BearerToken = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).token
    : false;

const apiURL = process.env.REACT_APP_API_URL;
const Sidebar = (props) => {
  const [progress,setProgress] = useState()
  const { data } = useContext(DashboardUserContext);
  const history = useHistory();
  const location = useLocation();
  // Profile image upload
  const imageUploader = React.useRef({profile});
  const sweetalert = function(e) {
    const [file] = e.target.files;
    console.log(e)
    swal({
      title: "Do you Want to Upload?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: {
        cancel:true,
        confirm:{
          text:"Upload"
        }
      },
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        console.log(e)
        handleImageUpload(file)
        swal("Upload in Progress")
      }
    });
  }
  const handleImageUpload = async (file) => {
    const url = `${apiURL}/api/user/add-user-photo`
    const bodyFormData = new FormData();
    // const [file] = e.target.files; 
    const storageRef = ref(storage, `${auth.currentUser.displayName}`);
    if (file) {
      bodyFormData.append('images', file);
      axios.post(url, bodyFormData, {headers: {
      token: `Bearer ${BearerToken()}`,
      'content-type': 'multipart/form-data'
    },onUploadProgress: data => {
      setProgress(Math.round((100*data.loaded)/data.total))
    }});
      // const reader = new FileReader();
      // const { current } = uploadedImage;
      // current.file = file;
      // reader.onload = e => {
      //   current.src = e.target.result;
      // };
      // reader.readAsDataURL(file);
      // Firebase Image Upload
      const uploadTask = uploadBytesResumable(storageRef,file);
      uploadTask.on("state_changed",(snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress)
        return progress;
      })
      uploadTask.on('state_changed', 
  (snapshot) => {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
    }
  }, 
  (error) => {
    // Handle unsuccessful uploads
  }, 
  () => {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
      try{
        await updateProfile(auth.currentUser,{
          photoURL:downloadURL
        })
        let dname;
        await getDoc(doc(db,"users",auth.currentUser.uid)).then(result => {
          dname = result.data().displayName;
        })
        await setDoc(doc(db, "users", auth.currentUser.uid), {
          uid: auth.currentUser.uid,
          displayName:dname,
          email:auth.currentUser.email,
          photoURL: downloadURL,
        });
        swal({
          text:"Uploaded Successfully",
          buttons:true
        }).then((result) => {
          if(result)
          window.location.reload()
        })
      }catch(err){
        console.log(err)
      }
      console.log('File available at', downloadURL);
    });
  }
);
      // uploadBytesResumable(storageRef,file).then(() => {
      //   getDownloadURL(storageRef).then(async (downloadURL) => {
      //     try{
      //       await updateProfile(auth.currentUser,{
      //         photoURL:downloadURL
      //       })
      //       let dname;
      //       await getDoc(doc(db,"users",auth.currentUser.uid)).then(result => {
      //         dname = result.data().displayName;
      //       })
      //       await setDoc(doc(db, "users", auth.currentUser.uid), {
      //         uid: auth.currentUser.uid,
      //         displayName:dname,
      //         email:auth.currentUser.email,
      //         photoURL: downloadURL,
      //       });
      //       swal({
      //         text:"Uploaded Successfully",
      //         buttons:true
      //       }).then((result) => {
      //         if(result)
      //         window.location.reload()
      //       })
      //     }catch(err){
      //       console.log(err)
      //     }
      //   })
      // })
    } 
  };


  return (
    <Fragment>
      <div className="flex flex-col w-full space-y-4 md:w-3/12 font-medium">
        <div
          // style={{ background: "#303031" }}
          className="flex flex-col items-center space-x-2 rounded p-2 text-gray-100"
        >
          {progress ? (<div className="text-black">Uploading: {progress}
          </div>) : (<span></span>) }
          
          {/* <svg
            className="cursor-pointer w-16 h-16 text-gray-100"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg> */}
          
            {/* <div className="flex flex-col"> */}
            {/* <img src={profile} className="rounded-full h-24 w-24" /> */}


            {/* Profile image  */}
            {/* <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: "none"
              }}
            /> */}
            {/* <div
              style={{
                height: "80px",
                width: "80px",
                border: "1px dashed black",
                borderRadius: "50%"
              }}
               onClick={() => imageUploader.current.click()}
            >
              <img
                ref={uploadedImage}
                style={{
                  // width: "100%",
                  // height: "100%",
                  width: "80px",
                  height: "80px",
                  position: "absolute",
                  borderRadius: "50%"
                }}
                href={data.userDetails ? `${apiURL}/api/photo/get-photo/${data.userDetails.userImage}` : ""}
                onError={(e) => (e.currentTarget.sec = {profile})}
                alt="userImage"
              />
            </div> */}
            <div className="userImage">
            <input type="file" name="images" id="images" onChange={(e) => sweetalert(e)}/>
            <div className="edit"><img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn4.iconfinder.com%2Fdata%2Ficons%2Fsoftware-menu-icons%2F256%2FSoftwareIcons-68-512.png&f=1&nofb=1" alt="edit" /></div>
              <img src={data.userDetails ? data.userDetails.userImage ? `${apiURL}/api/photo/get-photo/${data.userDetails.userImage}` : dp : dp} alt="userImage" />
            </div>
              {/* <span className="text-sm">Hello,</span> */}
              <span className="text-xl pl-2 text-black">

                {data.userDetails ? data.userDetails.name : ""}
              </span>
            {/* </div> */}
          
        </div>
        <div className="shadow hidden md:block w-full flex flex-col">
          <div
            onClick={(e) => history.push("/user/orders")}
            className={`${
              location.pathname === "/user/orders"
                ? "border-r-4 border-yellow-700 bg-gray-200"
                : ""
            }  px-4 py-4 hover:bg-gray-200 cursor-pointer`}
          >
            My Orders
          </div>
          <hr />
          {/* <div
            onClick={(e) => history.push("/user/products")}
            className={`${
              location.pathname === "/user/orders"
                ? "border-r-4 border-yellow-700 bg-gray-200"
                : ""
            }  px-4 py-4 hover:bg-gray-200 cursor-pointer`}
          >
            Add Product
          </div>
          <hr /> */}

          <div
            onClick={(e) => history.push("/user/profile")}
            className={`${
              location.pathname === "/user/profile"
                ? "border-r-4 border-yellow-700 bg-gray-200"
                : ""
            }  px-4 py-4 hover:bg-gray-200 cursor-pointer`}
          >
            My Accounts
          </div>
          <hr />
          
          {/* <div
            onClick={(e) => history.push("/wish-list")}
            className={` px-4 py-4 hover:bg-gray-200 cursor-pointer`}
          >
            My Wishlist
          </div>
          <hr /> */}
          <div
            onClick={(e) => history.push("/user/setting")}
            className={`${
              location.pathname === "/user/setting"
                ? "border-r-4 border-yellow-700 bg-gray-200"
                : ""
            }  px-4 py-4 hover:bg-gray-200 cursor-pointer`}
          >
            Setting
          </div>
          <hr />
          <div
            onClick={(e) => logout()}
            className={`${
              location.pathname === "/admin/dashboard/categories"
                ? "border-r-4 border-yellow-700 bg-gray-200"
                : ""
            }  px-4 py-4 hover:bg-gray-200 cursor-pointer`}
          >
            Logout
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;

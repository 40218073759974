
import { initializeApp } from "firebase/app";
import { getFirestore,addDoc,collection } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";
// const firebaseConfig = {
//   apiKey: "AIzaSyD3CjTl19FqmT-Tl4lcx4yzUuuxjoCJlbQ",
//   authDomain: "testing-13c28.firebaseapp.com",
//   projectId: "testing-13c28",
//   storageBucket: "testing-13c28.appspot.com",
//   messagingSenderId: "902515625834",
//   appId: "1:902515625834:web:8a46f0711e6a2d6a9d9f13"
// };
const firebaseConfig = {
  apiKey: "AIzaSyDHglaNYG23W6gHNq3bFQ0VFBLpdictMqo",
  authDomain: "sellular-firebase-db.firebaseapp.com",
  projectId: "sellular-firebase-db",
  storageBucket: "sellular-firebase-db.appspot.com",
  messagingSenderId: "886949586656",
  appId: "1:886949586656:web:88eac03286b30ef5811285",
  measurementId: "G-516VLKY69K"
};
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
const messagesRef = collection(db, "messages");
export const storage = getStorage();
export const addMessage = async (message, user, isReplying, reply) => {
  await addDoc(messagesRef, {
      text: message,
      createdAt: new Date(),
      user: {
          displayName: user.displayName,
          photoURL: user.photoURL,
          uid: user.uid,
          emailVerified: user.emailVerified
      },
      isEdited: false,
      editMetadata: {},
      isReplied: isReplying,
      replyMetadata: {
          ...reply
      },
  })
}
import React, { useEffect, useState } from 'react';
import './style.css';
import { getAllProduct, getCategories, getProductByCategory } from '../dashboardUser/FetchApi';
import ProductHeader from './ProductHeader';
function AllProducts() {
    const [categories, setCategories] = useState([]);
    const [loading,setLoading] = useState(true);
    const [noProducts,setNoProducts] = useState(false)
    useEffect(() => {
      let categorie = []
      getAllProduct().then(result => {
        console.log("Products :")
        if(result?.Products.length == 0){
          setNoProducts(true);
        }
      })
        getCategories().then(result => {
          // result.map(category => {
          //   getProductByCategory(category.cName).then(result => {
          //     if(result.length > 0){
          //       console.log(category.cName)
          //       categorie.push(category)
          //     }
          //   })
          // })
            setCategories(result);
            setLoading(false);
        })

    }, [])

    return (
      <div style={{ paddingBottom: "60px" }}>
        {/* Electronics Products */}
        <div className="all-products">
          {/* {loading ? <img src="https://media.tenor.com/GO77-LvOh9oAAAAj/cat-rainbow.gif" alt='loader'/> : <ProductHeader categories={categories} />}
          <ProductHeader categories={categories} /> */}
          {noProducts ? (
            <section>
            <h1>Add a few Products</h1>
          </section>
          ): loading ? <img src="https://media.tenor.com/GO77-LvOh9oAAAAj/cat-rainbow.gif" alt='loader'/> : <ProductHeader categories={categories} /> }
          
        </div>
      </div>
    );
}

export default AllProducts
import React, { Fragment, useState, useContext } from "react";
import { LayoutContext } from "../layout";
// import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import home from "./Footer-images/home.svg";
import search from "./Footer-images/search.svg";
import favorites from "./Footer-images/favorites.svg";
import inbox from "./Footer-images/inbox.svg";
import account from "./Footer-images/account.svg";
import { isAuthenticate } from "../auth/fetchApi";
import Login from "../auth/Login";
import { Link } from "react-router-dom";
import add from "./Footer-images/plus.png";
import dot from "./Footer-images/rec.png";
import chat from "./Footer-images/comment.png";
// import AdComponent from "./AdComp";


const Footer = (props) => {
  // Sets the dot below the footer icon dynamically according to the pathname
  const curr = window.location.pathname;
  const [item1, setActive1] = useState(curr === "/" ? true : false);
  const [item2, setActive2] = useState(curr === "/chat" ? true : false);
  const [item4, setActive4] = useState(curr === "/user/orders" ? true : false);
  const [item5, setActive5] = useState(curr === "/user/profile" ? true : false);
  const { data, dispatch } = useContext(LayoutContext);

  const loginModalOpen = () =>
    data.loginSignupModal
      ? dispatch({ type: "loginSignupModalToggle", payload: false })
      : dispatch({ type: "loginSignupModalToggle", payload: true });
  return (
    <Fragment>
      <div className="nav">
        <div className="w-full">
          {/* <section id="bottom-navigation" class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> */}
          <section
            id="bottom-navigation"
            className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"
          >
              {/* <AdComponent /> */}
            {/* <div className="h-12 w-full bg-black text-white text-center m-auto">
              <h6>Ad space</h6>
            </div> */}
            <div id="tabs" className="flex justify-between">
              <Link
                to="/"
                className="w-full justify-center inline-block text-center pt-2 pb-1"
                // onClick={ footerSelect1 }
              >
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 42 42"
                  className="inline-block mb-1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    {/* <path d="M21.0847458,3.38674884 C17.8305085,7.08474576 17.8305085,10.7827427 21.0847458,14.4807396 C24.3389831,18.1787365 24.3389831,22.5701079 21.0847458,27.6548536 L21.0847458,42 L8.06779661,41.3066256 L6,38.5331279 L6,26.2681048 L6,17.2542373 L8.88135593,12.4006163 L21.0847458,2 L21.0847458,3.38674884 Z" fill="currentColor" fillOpacity="0.1"></path> */}
                    <path
                      d="M11,8 L33,8 L11,8 Z M39,17 L39,36 C39,39.3137085 36.3137085,42 33,42 L11,42 C7.6862915,42 5,39.3137085 5,36 L5,17 L7,17 L7,36 C7,38.209139 8.790861,40 11,40 L33,40 C35.209139,40 37,38.209139 37,36 L37,17 L39,17 Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M22,27 C25.3137085,27 28,29.6862915 28,33 L28,41 L16,41 L16,33 C16,29.6862915 18.6862915,27 22,27 Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="currentColor"
                      fillOpacity="0.1"
                    ></path>
                    <rect
                      fill="currentColor"
                      transform="translate(32.000000, 11.313708) scale(-1, 1) rotate(-45.000000) translate(-32.000000, -11.313708) "
                      x="17"
                      y="10.3137085"
                      width="30"
                      height="2"
                      rx="1"
                    ></rect>
                    <rect
                      fill="currentColor"
                      transform="translate(12.000000, 11.313708) rotate(-45.000000) translate(-12.000000, -11.313708) "
                      x="-3"
                      y="10.3137085"
                      width="30"
                      height="2"
                      rx="1"
                    ></rect>
                  </g>
                </svg>
                {item1 && (
                  <div className="flex justify-center" id="item1">
                    <img src={dot} height="10" width="10" />
                  </div>
                )}
                {/* <span class="tab tab-home block text-xs no-underline footer-item">&#9679;</span> */}
              </Link>

              {isAuthenticate() ? (
                <Link
                  to="/chat"
                  className="w-full justify-center inline-block text-center pt-2 pb-1"
                  // onClick={ footerSelect2 }
                >
                  <img src={chat} width="25" height="25" className="inline-block mb-1" />
                  {/* <svg
                    width="25"
                    height="25"
                    viewBox="0 0 42 42"
                    className="inline-block mb-1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g transform="translate(2.000000, 3.000000)">
                        <path
                          d="M8.5,1 C4.35786438,1 1,4.35786438 1,8.5 L1,13 C1,14.6568542 2.34314575,16 4,16 L13,16 C14.6568542,16 16,14.6568542 16,13 L16,4 C16,2.34314575 14.6568542,1 13,1 L8.5,1 Z"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></path>
                        <path
                          d="M4,20 C2.34314575,20 1,21.3431458 1,23 L1,27.5 C1,31.6421356 4.35786438,35 8.5,35 L13,35 C14.6568542,35 16,33.6568542 16,32 L16,23 C16,21.3431458 14.6568542,20 13,20 L4,20 Z"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></path>
                        <path
                          d="M23,1 C21.3431458,1 20,2.34314575 20,4 L20,13 C20,14.6568542 21.3431458,16 23,16 L32,16 C33.6568542,16 35,14.6568542 35,13 L35,8.5 C35,4.35786438 31.6421356,1 27.5,1 L23,1 Z"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></path>
                        <path
                          d="M34.5825451,33.4769886 L38.3146092,33.4322291 C38.8602707,33.4256848 39.3079219,33.8627257 39.3144662,34.4083873 C39.3145136,34.4123369 39.3145372,34.4162868 39.3145372,34.4202367 L39.3145372,34.432158 C39.3145372,34.9797651 38.8740974,35.425519 38.3265296,35.4320861 L34.5944655,35.4768456 C34.048804,35.4833899 33.6011528,35.046349 33.5946085,34.5006874 C33.5945611,34.4967378 33.5945375,34.4927879 33.5945375,34.488838 L33.5945375,34.4769167 C33.5945375,33.9293096 34.0349773,33.4835557 34.5825451,33.4769886 Z"
                          fill="currentColor"
                          transform="translate(36.454537, 34.454537) rotate(-315.000000) translate(-36.454537, -34.454537) "
                        ></path>
                        <circle
                          stroke="currentColor"
                          strokeWidth="2"
                          cx="27.5"
                          cy="27.5"
                          r="7.5"
                        ></circle>
                      </g>
                    </g>
                  </svg> */}
                  {item2 && (
                    <div className="flex justify-center" id="item2">
                      <img src={dot} height="10" width="10" />
                    </div>
                  )}
                </Link>
              ) : (
                <div
                  className="w-full justify-center inline-block text-center pt-2 pb-1"
                  // onClick={ footerSelect2 }
                  onClick={(e) => loginModalOpen()}
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 42 42"
                    className="inline-block mb-1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g transform="translate(2.000000, 3.000000)">
                        <path
                          d="M8.5,1 C4.35786438,1 1,4.35786438 1,8.5 L1,13 C1,14.6568542 2.34314575,16 4,16 L13,16 C14.6568542,16 16,14.6568542 16,13 L16,4 C16,2.34314575 14.6568542,1 13,1 L8.5,1 Z"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></path>
                        <path
                          d="M4,20 C2.34314575,20 1,21.3431458 1,23 L1,27.5 C1,31.6421356 4.35786438,35 8.5,35 L13,35 C14.6568542,35 16,33.6568542 16,32 L16,23 C16,21.3431458 14.6568542,20 13,20 L4,20 Z"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></path>
                        <path
                          d="M23,1 C21.3431458,1 20,2.34314575 20,4 L20,13 C20,14.6568542 21.3431458,16 23,16 L32,16 C33.6568542,16 35,14.6568542 35,13 L35,8.5 C35,4.35786438 31.6421356,1 27.5,1 L23,1 Z"
                          stroke="currentColor"
                          strokeWidth="2"
                        ></path>
                        <path
                          d="M34.5825451,33.4769886 L38.3146092,33.4322291 C38.8602707,33.4256848 39.3079219,33.8627257 39.3144662,34.4083873 C39.3145136,34.4123369 39.3145372,34.4162868 39.3145372,34.4202367 L39.3145372,34.432158 C39.3145372,34.9797651 38.8740974,35.425519 38.3265296,35.4320861 L34.5944655,35.4768456 C34.048804,35.4833899 33.6011528,35.046349 33.5946085,34.5006874 C33.5945611,34.4967378 33.5945375,34.4927879 33.5945375,34.488838 L33.5945375,34.4769167 C33.5945375,33.9293096 34.0349773,33.4835557 34.5825451,33.4769886 Z"
                          fill="currentColor"
                          transform="translate(36.454537, 34.454537) rotate(-315.000000) translate(-36.454537, -34.454537) "
                        ></path>
                        <circle
                          stroke="currentColor"
                          strokeWidth="2"
                          cx="27.5"
                          cy="27.5"
                          r="7.5"
                        ></circle>
                      </g>
                    </g>
                  </svg>
                  {item2 && (
                    <div className="flex justify-center" id="item2">
                      <img src={dot} height="10" width="10" />
                    </div>
                  )}
                </div>
              )}

              {isAuthenticate() ? (
                <Link
                  to="/user/products"
                  className="w-full justify-center inline-block text-center pt-2 pb-1"
                >
                  <img
                    src={add}
                    width="40"
                    height="40"
                    className="inline-block mb-1"
                  />
                </Link>
              ) : (
                <div
                  onClick={(e) => loginModalOpen()}
                  className="w-full justify-center inline-block text-center pt-2 pb-1"
                >
                  <img
                    src={add}
                    width="40"
                    height="40"
                    className="inline-block mb-1"
                  />
                </div>
              )}

              {isAuthenticate() ? (
                <Link
                  to="/user/orders"
                  className="w-full justify-center inline-block text-center pt-2 pb-1"
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 512 512"
                    className="inline-block mb-1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <path
                        d="M448 341.37V170.61A32 32 0 00432.11 143l-152-88.46a47.94 47.94 0 00-48.24 0L79.89 143A32 32 0 0064 170.61v170.76A32 32 0 0079.89 369l152 88.46a48 48 0 0048.24 0l152-88.46A32 32 0 00448 341.37z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="28"
                      />
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="28"
                        d="M69 153.99l187 110 187-110M256 463.99v-200"
                      />
                    </g>
                  </svg>
                  {item4 && (
                    <div className="flex justify-center" id="item4">
                      <img src={dot} height="10" width="10" />
                    </div>
                  )}
                </Link>
              ) : (
                <div
                  onClick={(e) => loginModalOpen()}
                  className="w-full justify-center inline-block text-center pt-2 pb-1"
                >
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 512 512"
                    className="inline-block mb-1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <path
                        d="M448 341.37V170.61A32 32 0 00432.11 143l-152-88.46a47.94 47.94 0 00-48.24 0L79.89 143A32 32 0 0064 170.61v170.76A32 32 0 0079.89 369l152 88.46a48 48 0 0048.24 0l152-88.46A32 32 0 00448 341.37z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="28"
                      />
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="28"
                        d="M69 153.99l187 110 187-110M256 463.99v-200"
                      />
                    </g>
                  </svg>
                  {item4 && (
                    <div className="flex justify-center" id="item4">
                      <img src={dot} height="10" width="10" />
                    </div>
                  )}
                </div>
              )}

              {isAuthenticate() ? (
                <Link
                  to="/user/profile"
                  className="w-full justify-center inline-block text-center pt-2 pb-1 no-underline"
                  // onClick={ footerSelect5 }
                >
                  <svg
                    width="25"
                    height="25"
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline-block mb-1"
                    viewBox="0 0 512 512"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <path
                        d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="32"
                      />
                      <path
                        d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="32"
                      />
                    </g>
                  </svg>
                  {item5 && (
                    <div className="flex justify-center" id="item5">
                      <img src={dot} height="10" width="10" />
                    </div>
                  )}
                </Link>
              ) : (
                <div
                  onClick={(e) => loginModalOpen()}
                  className="w-full justify-center inline-block text-center pt-2 pb-1 no-underline"
                  // onClick={ footerSelect5 }
                >
                  <svg
                    width="25"
                    height="25"
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline-block mb-1"
                    viewBox="0 0 512 512"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <path
                        d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="32"
                      />
                      <path
                        d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="32"
                      />
                    </g>
                  </svg>
                  {item5 && (
                    <div className="flex justify-center" id="item5">
                      <img src={dot} height="10" width="10" />
                    </div>
                  )}
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;

import React, { useEffect, useState } from 'react'
import ProductContainer from './ProductContainer';
import {Link} from 'react-router-dom';
const ProductHeader = ({ categories }) => {
    return (
        <div>
            {categories?.map(category => (
                <div className='all-products'>
                    <div className='product-header'>
                        <div className='recommended-for-you'>
                            {category.cName}
                        </div>
                        <div className='view-all'>
                            <Link to={`/category/${category.cName}`}>View all</Link>
                            {/* <a href={`/category/${category.cName}`}>View all</a> */}
                        </div>
                    </div>
                    <ProductContainer category={category.cName} />
                </div>
            ))}

        </div>
    )

}

export default ProductHeader
import React, { Fragment, useEffect, useContext, useState } from "react";
import moment from "moment";
import { fetchOrderByUser } from "./Action";
import Layout from "./Layout";
// import mobile from "./images/mobile.png";
// import mobile2 from "./images/mobile2.png";
// import beauty2 from "./images/beauty2.png";
import {deleteProduct,productByUser } from "./products/FetchApi";
// import { productState, productReducer } from "./ProductContext";
import { ProductContext } from "./Layout";
import EditProductModal from "./EditProductModal";
import swal from 'sweetalert';

const apiURL = process.env.REACT_APP_API_URL;
 /* This method call the editmodal & dispatch product context */
const OrdersComponent = () => {
  let isLoading = false;
  const { data2, dispatch2 } = useContext(ProductContext);
  const editProduct = (pId, product, type) => {
    console.log(product)
   if (type) {
     dispatch2({
       type: "editProductModalOpen",
       product: { ...product, pId: pId },
     });
   }
 };
  const [product,setProduct] = useState([])
  // const {products } = data;
  useEffect(() => {
    fetchData();
  },[])
  const fetchData = async () => {
    productByUser().then(result => {
      setProduct(result.Products)
    })
  };
  const sweetAlert = (item_id) => {
    swal({
      title: "Are you sure?",
      // text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        handleSubmit(item_id)
      }
    });
  }
  useEffect(() => {
    fetchOrderByUser(dispatch2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (id) =>{
    setProduct([])
    isLoading=true;
    const c = await deleteProduct(id);
    if(c.success){
      await fetchData();
      isLoading=false;
      // window.location.reload();
    }
  }
  if (isLoading) {
    return (
      <div className="w-full md:w-9/12 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }
  return (
    <Fragment>
      {/* New Orders design */}
      {isLoading ? (
        <div className="flex items-center justify-center p-8">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
      ) :(
        <div className="flex flex-col mb-40 divide-y">
      {product && product.length > 0 ? (
              product.map((item, key) => {
                return (
                  <div className="w-full h-24 flex flex-row" key={key}>
          <div className="w-1/3">
            <img 
              className="h-20 w-20 p-2 object-scale-down object-center"
              src={`${apiURL}/api/photo/get-photo/${item.pImages[0]}`} alt="Product"
            />
          </div>
          <div className="w-2/3 text-left items-center p-2">
            <h5>{item.pName}</h5>
            <p className="text-sm truncate">{item.pDescription}</p>
          </div>
          <span
            onClick={(e) => editProduct(item._id, item, true)}
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
          >
            <svg
              className="w-6 h-6 fill-current text-green-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
              <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span
            // onClick={(e) => handleSubmit(item._id)}
             onClick={(e)=>sweetAlert(item._id)}
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1"
          >
            <svg
              className="w-6 h-6 fill-current text-red-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
                );
              })
              // This is the loading screen that works
            ) : (
              <div className="text-center">
                <h3>No product found</h3>
              </div>
              /* <span>
                <div className="w-full md:w-9/12 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
                  </span> */
            )}
      </div>
      )}
      
      <EditProductModal />
    </Fragment>
  );
};

const UserOrders = (props) => {
  
  
  return (
    <Fragment>
      <Layout children={<OrdersComponent />} />
    </Fragment>
  );
};

export default UserOrders;

import React, { useContext } from 'react'
import {signOut} from "firebase/auth"
import { auth, db } from '../../../../firebase'
import { AuthContext } from '../../../context/AuthContext'
import { useEffect } from 'react'
import { doc, getDoc } from 'firebase/firestore'
import { useState } from 'react'
const Navbar = () => {
  const {currentUser} = useContext(AuthContext)
  
  return (
    <div className='navbar'>
      <span className="logo">Chat</span>
      <div className="user">
      {/* <img src={data.userDetails ? data.userDetails.userImage ? `${apiURL}/api/photo/get-photo/${data.userDetails.userImage}` : "https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=6&m=1223671392&s=170667a&w=0&h=zP3l7WJinOFaGb2i1F4g8IS2ylw0FlIaa6x3tP9sebU=" : "https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=6&m=1223671392&s=170667a&w=0&h=zP3l7WJinOFaGb2i1F4g8IS2ylw0FlIaa6x3tP9sebU="} alt="userImage" /> */}
        <img src={currentUser.photoURL ? currentUser.photoURL : "https://media.istockphoto.com/vectors/default-profile-picture-avatar-photo-placeholder-vector-illustration-vector-id1223671392?k=6&m=1223671392&s=170667a&w=0&h=zP3l7WJinOFaGb2i1F4g8IS2ylw0FlIaa6x3tP9sebU=" } alt="" />
        <span>{currentUser.displayName }</span>
      </div>
    </div>
  )
}

export default Navbar